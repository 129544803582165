<template>
  <li class="participant">

    <router-link :title="name" class="profile" :alt="name" :style="style" :to="{ name: 'users', params: { id: participant.user }}">
      <svg v-if="icon" v-html="icon"/>
    </router-link>

  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConversationHeaderParticipant',

  props: {
    participant: Object
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    isMe() {
      return this.$vnode.key === this.me
    },

    style() {
      return this.$Utils.User.image( this.participant.image, this.participant.color )
    },

    name() {
      return this.$Utils.User.name( this.participant )
    },

    icon() {
      const icon = this.participant.icon
      if ( this.participant.image || !icon ) return
      return this.$Utils.User.icon( icon )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.participant {
  @include flex-center;

  //Profile picture
  span {
    width: 44px;
    min-width: 44px;
    height: 44px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
