<template>
  <div class="message-image">

    <div :style="preview"></div>

  </div>
</template>

<script>
export default {
  name: 'ConversationMessageImage',

  props: {
    url: String
  },

  computed: {
    preview() {
      if ( !this.url ) return null
      return `background-image: url(${ this.url });`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.message-image {
  background-color: $light-grey;
  border: 1px solid $med-light-grey;
  border-radius: 2px;
  width: 100%;
  margin: 8px 0;

  div {
    @include image-center;
    height: 240px;
    width: 100%;
    min-width: 240px;
    margin: 0 auto;
    border-radius: 1px;

    @media ( max-width: $mobile ) {
      height: 160px;
      min-width: 160px;
    }
  }
}
</style>
