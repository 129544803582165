<template>
  <li class="remainder">

    <span class="profile" :alt="remainder" :title="remainder">
      <h2>+{{ cap }}</h2>
    </span>
    
  </li>
</template>

<script>
export default {
  name: 'ConversationHeaderRemainder',

  props: {
    remainder: Number
  },

  computed: {
    cap() {
      return this.remainder > 99 ? 99 : this.remainder
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.remainder {
  @include flex-center;

  //Profile picture
  span {
    width: 44px;
    min-width: 44px;
    height: 44px;
    background-color: $white;
    cursor: default;

    h2 {
      font-size: 16px;
      letter-spacing: 1px;
    }
  }
}
</style>
