<template>
  <div class="conversation-header">
    <ul>

      <Participant v-for="participant in participants.slice( 0, limit )" :key="participant.user" :participant="participant"/>
      
      <Remainder v-if="remainder" :remainder="remainder"/>

    </ul>

    <h1 :title="title">{{ names }}</h1>

  </div>
</template>

<script>
import Participant from '@/components/conversation/header/Participant.vue'
import Remainder from '@/components/conversation/header/Remainder.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ConversationHeader',

  data() {
    return {
      limit: 3
    }
  },

  components: {
    Participant,
    Remainder
  },

  computed: {
    ...mapGetters( 'Conversation', {
      participants_: 'participants'
    }),

    ...mapGetters( 'Auth', {
      me: 'id'
    }),
    
    //Without me
    participants() {
      const isNotMe = participant => participant.user !== this.me
      return this.participants_.filter( isNotMe )
    },

    remainder() {
      const count = this.participants.length
      if ( count < this.limit ) return null
      return ( count > this.limit ) ? count - this.limit : null
    },

    names() {
      return this.participants.map( o => o.name = this.$Utils.User.name( o )).join( ', ' )
    },

    title() {
      const count = this.participants_.length
      if ( count < 3 ) return this.names
      return `${ count + 1 } Members`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.conversation-header {
  width: 100%;
  padding: $page-padding;
  background-color: $off-white;
  border: 1px solid $med-light-grey;
  border-top: none;
  display: grid;
  grid-gap: $page-padding;
  grid-template-columns: auto 1fr;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  @media ( max-width: $tablet ) {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $med-light-grey;
  }

  ul {
    @include flex;

    li {
      &:not( :first-child ) {
        //Overlap
        margin-left: -18px;
      }
    }
  }

  h1 {
    font-size: 16px;
    @include ellipsis;
    text-align: left;

    @media ( max-width: $tablet ) {
      font-size: 14px;
    }
  }
}
</style>
