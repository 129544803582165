<template>
  <div class="message-user">

    <router-link :title="name" class="profile" :alt="name" :style="style" :to="{ name: 'users', params: { id: message.user }}">
      <svg v-if="icon" v-html="icon"/>
    </router-link>

  </div>
</template>

<script>
import Filters from '@/utils/filters'
import { mapGetters } from 'vuex'

export default {
  name: 'ConversationMessageUser',

  props: {
    message: Object
  },

  filters: {
    ...Filters
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    style() {
      return this.$Utils.User.image( this.message.profile, this.message.color )
    },

    name() {
      return this.$Utils.User.name( this.message )
    },
    
    icon() {
      if ( this.message.profile ) return
      return this.$Utils.User.icon( this.message.icon )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.message-user {
  cursor: pointer;
  grid-area: user;

  @media ( max-width: $mobile ) {
    display: none;
  }

  //Profile picture
  span {
    width: 42px;
    height: 42px;
    min-width: 42px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
