<template>
  <li class="chat-message" :class="{ me: isMe }">

    <User v-if="!isMe" :message="message"/>

    <Content :message="message"/>

  </li>
</template>

<script>
import User from '@/components/conversation/message/User.vue'
import Content from '@/components/conversation/message/Content.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'ConversationMessage',

  props: {
    message: Object
  },

  components: {
    User,
    Content
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    isMe() {
      return this.me === this.message.user
    }
  }
}
</script>