<template>
  <form @submit.prevent="submit" :class="{ loaded }">
    <div class="input-container">

      <input type="text" placeholder="Say Hello" v-model="text" :maxlength="max"/>
      
      <span @click="trigger" title="Add attachment">
        <svg :class="{ hasAttachment: image }" fill="none" stroke-width="1px" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="none"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
      </span>

      <!-- Hidden file trigger -->
      <input ref="image" type="file" accept="image/png, image/jpeg" @click="reset" @change="upload"/>

      <Insufficient v-if="insufficient" :close="() => this.insufficient = false" :price="this.price"/>

    </div>
  </form>
</template>

<script>
import Insufficient from '@/components/shop/Insufficient.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConversationSend',

  props: {
    scroll: Function,
    loaded: Boolean
  },

  data() {
    return {
      image: null,
      text: '',
      insufficient: false,
    }
  },

  components: {
    Insufficient
  },

  computed: {
    ...mapGetters( 'Limits', {
      limit: 'messages',
      s3: 's3'
    }),

    ...mapGetters( 'Prices', {
      price: 'message'
    }),

    ...mapGetters( 'Auth', {
      balance: 'balance'
    }),

    max() {
      return this.limit.characters
    },

    canSend() {
      return this.text.trim() && this.loaded
    },

    cost() {
      return this.price ? this.$Utils.Filters.abbriviate( this.price ) : null
    }
  },

  methods: {
    ...mapActions( 'Conversation', {
      send: 'send'
    }),

    async submit() {
      if ( !this.canSend ) return
      if ( this.price && this.image && this.balance < this.price ) return this.insufficient = true
      const conversation = this.$route.params.id
      this.send({ conversation, text: this.text, image: this.image })
      this.text = ''
      this.image = null

      this.$nextTick(() => {
        this.scroll()
      })
    },

    async trigger() {
      if ( this.image ) return
      this.$refs.image.click()
    },

    async upload( e ) {
      const file = e.target.files[ 0 ]
      const { image: limit } = this.s3
      if ( !file || file.size > limit ) return
      this.image = file
    },

    async reset( e ) {
      e.target.value = null
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

form {
  padding: $page-padding;
  padding-top: 0;
  position: relative;
  width: 100%;

  &:not( .loaded ) {
    pointer-events: none;
  }

  .input-container {
    width: 100%;
    border-radius: 4px;
    @include box-shadow;
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: $white;

    input[ type = text ] {
      font-size: 16px;
      box-shadow: unset;
      background-color: unset;
      padding-right: 0;
    }

    span {
      @include flex-center;
      height: auto;
      width: 22px;
      cursor: pointer;
      margin: 0 12px;

      svg {
        stroke: $black;
        width: 22px;
        height: 22px;

        &.hasAttachment {
          stroke: $primary-color;
        }
      }
    }
  }
}
</style>
